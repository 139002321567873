import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://www.lagersmit.com/media/1009/logo.svg?mode=pad&rnd=132098262510000000" alt=""/>
        <p>
          No servicestation has been recognized
        </p>
        <a>
          Please try again
        </a>
        
      </header>
    </div>
  );
}

export default App;
